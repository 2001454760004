import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GDPRData } from '../types';
import './iframe.scss';

const GDPR: React.FC = () => {
  const GDPRdata: GDPRData = useStaticQuery(graphql`
        query {
            markdownRemark(fileAbsolutePath: {regex: "/notice.md/"}) {
                frontmatter {
                    title
                }
            html
            }
        }
    `);

  const { html } = GDPRdata.markdownRemark;

  return (
    <div className='iframe-container' >
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};

export default GDPR;
